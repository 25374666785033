/* eslint-disable react-hooks/rules-of-hooks */
import { Footer, Navbar } from '@pwskills/rachnaui';
import { ICategory, IUser } from '@pwskills/rachnaui/components/Navbar/Navbar.types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import UserDetailsContext from '@/context/UserDetailsContext';
import {
  accountUrl,
  // googleClientId,
  baseApiUrl,
  s3Url,
  environment,
  domainUrl,
} from '@/utils/common/env.constants';
import { getAccessToken, getProfileUrl, logout } from '@/utils/helper/helperFunction';
import {
  CompanyFooterLink,
  ProductListFooterLink,
  ProductListNavLink,
  renderPwSkillsUrl,
} from '@/utils/homePage/home.constants';
import useInitLogin from '@/hooks/useInitLogin';
import Loader from '@/components/common/Loader';
import { dataLayer } from '@/utils/common/dataLayer.utils';
import { Linkedin, TelegramCircle, Youtube } from '@pwskills/rachnaui/Icons';
import useLocationInfo from '@/hooks/useLocationInfo';
import { classNames, randomId } from '@pwskills/rachnaui/utils';
import { HelpCentreToggle, PurchaseOptionToggle } from '@/interfaces/api.types';
import sendEventOnClick from '@/utils/DataLayerEventFunctions';
import { GAevent } from '../../../utils/firebase.utils';
import { noCookieDomains } from '../video/utils';

type Props = {
  children: React.ReactNode;
  category?: ICategory[];
  enableFooter?: boolean;
  isProfessionalTheme?: boolean;
  updateColor?: boolean;
  pageName?: string;
};

// BaseLayout page
const BaseLayout = ({
  children,
  category,
  updateColor,
  isProfessionalTheme = false,
  enableFooter = true,
  pageName,
}: Props) => {
  const [token, setToken] = useState<string>('');
  const { user, isLoggedIn } = useContext(UserDetailsContext);
  const { isLoading } = useInitLogin();
  const { host, origin } = useLocationInfo();
  const enableHelpCentre = process.env.NEXT_PUBLIC_HELP_CENTRE_ENABLE === HelpCentreToggle.true;
  const enablePurchaseMenu =
    process.env.NEXT_PUBLIC_PURCHASE_MENU_ENABLE === PurchaseOptionToggle.true;

  const router = useRouter();

  const searchApi = `${baseApiUrl}/v2/course/search?limit=10&page=1`;
  const socialIconLinks = [
    {
      name: 'Linkedin',
      icon: <Linkedin />,
      href: 'https://www.linkedin.com/company/pw-skills-official/',
    },
    {
      name: 'Youtube',
      icon: <Youtube />,
      href: 'https://www.youtube.com/@PWSkillsTech',
    },
    {
      name: 'Telegram',
      icon: <TelegramCircle />,
      href: 'https://t.me/SkillsPW',
    },
  ];

  const userDataLayerEvent = () => {
    const utmQuery = useUTMParameters();
    const userDataLayerObject = {
      event: 'user_details',
      user_type: isLoggedIn ? 'logged_in' : 'guest',
      user_name: isLoggedIn ? `${user.firstName} ${user.lastName || ''}` : null,
      email: isLoggedIn ? user.email : null,
      number: isLoggedIn ? `${user.isdCode}${user.phoneNumber || ''}` : null,
      country:
        (typeof window !== 'undefined' && window.localStorage.getItem('USER_COUNTRY')) || null,
      user_category: null,
      user_id: isLoggedIn ? user._id : null,
      gclid: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      ...utmQuery,
    };
    dataLayer(userDataLayerObject);
  };

  const obj: IUser = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    isdCode: user?.isdCode,
    _id: user?._id,
    verified: user?.verified,
    phoneNumber: user?.phoneNumber,
    img: user?.img && getProfileUrl(user?.img),
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setToken(getAccessToken() || '');
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      userDataLayerEvent();
    }
  }, [router.isReady, isLoggedIn]);

  // useOneTapLogin(
  //   {
  //     client_id: googleClientId,
  //     context: 'signin',
  //     isLoggedIn,
  //   },
  //   (response) => {
  //     window.location.href = `${accountUrl}/login?domain=${host}&redirectUrl=${router.asPath}&google_token=${response.credential}`;
  //   }
  // );

  const handleLogoutClick = () => {
    GAevent('sk_logout_click', {
      user_id: user._id,
      user_name: `${user.firstName} ${user.lastName}`,
      user_email: user.email,
      user_phonenumber: `${user.isdCode} ${user.phoneNumber}`,
      source: 'navbar',
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <main className={updateColor ? `bg-[#f8f8f8]` : 'bg-white'}>
      {/* isProfessionalTheme has different navbar and amateur theme has different */}
      {isProfessionalTheme ? (
        <nav
          className={classNames(
            !isProfessionalTheme ? '[&>div]:bg-white' : '[&>div]:bg-gray-900',
            '[&>div>div>div>div>div>ul>li:first-child>span:last-child]:!break-words',
            'z-[9999] relative [&>div>div]:large:!py-4'
          )}
        >
          <Navbar
            category={undefined}
            cardImgLink={`${s3Url}/assets/uploads/thumbnails`}
            homeLink={origin}
            aTag={Link}
            menu={[]}
            user={obj}
            isCoursesMenu
            enablePurchaseMenu={enablePurchaseMenu}
            onPurchasesClick={() => {
              router.push('/purchases');
            }}
            onLogoutClick={() => {
              handleLogoutClick();
              logout();
            }}
            onProfileClick={() => {
              window.location.href = `${accountUrl}/profile`;
            }}
            onCoursesClick={() => {
              router.push('/learn');
            }}
            token={token}
            onLoginRegisterClick={() => {
              let hrefUrl = `${accountUrl}/login?domain=${host}&redirectUrl=${router.asPath}`;
              if (!noCookieDomains.includes(environment)) hrefUrl += '&loginType=cookie';
              window.location.href = hrefUrl;
            }}
            variant="default"
            searchApi=""
            siteMetaApi=""
            logoColor={!isProfessionalTheme ? 'black' : 'white'}
            enableHelpCentre={enableHelpCentre}
            onSupportClick={() => {
              router.push('/support');
            }}
            domain={domainUrl}
            baseApiUrl={baseApiUrl}
            pageName={pageName || ''}
          />
        </nav>
      ) : (
        <nav
          className={classNames(
            '[&>div>div>div>div>div>ul>li:first-child>span:last-child]:!break-words',
            'z-[9999] relative'
          )}
        >
          <Navbar
            category={category || []}
            cardImgLink={`${s3Url}/assets/uploads/thumbnails`}
            homeLink={origin}
            siteMetaApi={`${baseApiUrl}/v2/course/sitemeta?platformType=main&random_id=${randomId()}`}
            aTag={Link}
            menu={ProductListNavLink}
            user={obj}
            isCoursesMenu
            onLogoutClick={() => {
              handleLogoutClick();
              logout();
            }}
            onProfileClick={() => {
              window.location.href = `${accountUrl}/profile`;
            }}
            onCoursesClick={() => {
              router.push('/learn');
            }}
            token={token}
            onLoginRegisterClick={() => {
              let hrefUrl = `${accountUrl}/login?domain=${host}&redirectUrl=${router.asPath}`;
              if (!noCookieDomains.includes(environment)) hrefUrl += '&loginType=cookie';
              window.location.href = hrefUrl;
            }}
            variant="search"
            searchApi={searchApi}
            enableHelpCentre={enableHelpCentre}
            onSupportClick={() => {
              router.push('/support');
            }}
            enablePurchaseMenu={enablePurchaseMenu}
            onPurchasesClick={() => {
              router.push('/purchases');
            }}
            sendEventOnClick={sendEventOnClick}
            domain={domainUrl}
            baseApiUrl={baseApiUrl}
            pageName={pageName || ''}
          />
        </nav>
      )}
      {children}
      {enableFooter && (
        <footer>
          <Footer
            products={ProductListFooterLink}
            company={CompanyFooterLink}
            homeLink={renderPwSkillsUrl}
            phone="+91 6361388292"
            socialIcon={socialIconLinks}
          />
        </footer>
      )}
    </main>
  );
};

export default BaseLayout;
